.nrPop{
    position: absolute;
    top: 0px;
    pointer-events: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.nrPopBody{
    display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0;
    background-color: white;
}
.nrTest{
    display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0;
}
.nrPopFullRow{
    display: flex;
    flex: 1 1 auto;
    min-height: 0;
    overflow-y: auto;
    padding: 3px;
}

.nrPopFullCol{
    display: flex;
    flex: 1 1 auto;
    padding: 3px;
    min-height: 0;
    overflow-y: auto;
}

.nrPopHeader{
    display: flex;
    flex-basis: 45px;
    border-bottom: 1px solid #ccc;
    background-color: #8E8E93;
    align-items: center;
    justify-content: space-between;
    padding: 5px 8px;
    color: white;
}
.nrPopFooter{
    display: flex;
    flex-shrink: 0;
    flex-basis: 40px;
    border-top: 1px solid #ccc;
    background-color: #8E8E93;
    align-items: center;
    justify-content: center;
}

.nrTabs{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 0 8px;
}
.nrTab{
    display: flex;
    flex: 1;
    align-self: center;
    justify-content: center;
}
.nrTabActive{
    color: #EBB31B;
}
.nrTabs .ant-divider-vertical{
    height: 20px;
    border-left-color: #EBB31B;
}
.nrTab a{
    display: block;
    color: white;
    width: 100%;
    text-align: center;
    padding: 8px 0;
}

.ant-modal{
    padding-bottom: 0;
}

.nrCollapse .ant-collapse-content > .ant-collapse-content-box{
    padding: 0;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header{
    padding: 3px 40px 3px 3px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    padding-top: 3px;
}

.nrBorderedBox{
    border: 1px solid #D9D9D9;
    padding: 3px;
    width: 100%;
    background-color: #FAFAFA;
}

.modalFullHeight .ant-modal{
    height: calc(100vh - 30px);
    overflow-y: hidden;
    margin: 15px 0;
}

.modalFullHeight .ant-modal .ant-modal-content{
    height: 100%;
    overflow-y: hidden;
}

.modalFullHeight .ant-modal .ant-modal-content .ant-modal-body{
    height: calc(100vh - 85px);
    background-color: white;
    margin: 0;
    padding: 10px;
    overflow-y: hidden;
}

.w-100{
    width: 100%;
}

.popClose{
    color: white;
    font-size: 18px;
}

.nrTitle{
    font-size: 0.9rem;
    font-weight: 500;
}

.searchBox{
    width: 200px;
}

.thActions{
    width: 10px;
    padding-left: 0;
}

.nrBreadcrumbItm {
    text-decoration: underline;
    margin: 0;
    padding: 0;
}

.lstItmTxt{
    font-size: 0.95rem;
    margin-bottom: 0;
}
.lstItmDesc{
    font-size: 0.75rem;
    color: #ABABAB;
    margin-top: 0.15rem;
    margin-bottom: 0;
}
